import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './Components/Home/Home';
import ContactTable from './Components/Tables/ContactTable';
import Header from './Components/Header/Header';
import CurriculumTable from './Components/Tables/CurriculumTable';
import SchoolTable from './Components/Tables/SchoolTable';
import Prensa from './Components/Prensa/PrensaForm';
import Cotizadores from './Components/Tables/Cotizadores';
import PrensaTable from './Components/Prensa/PrensaTable';
import { UserProvider } from './Context/context';

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
      <Header />
        <Routes>
          <Route exact path="/prensa" element={<Prensa />} />
          <Route exact path="/prensa-table" element={<PrensaTable />} />
          <Route exact path="/cotizadores" element={< Cotizadores/>} />
          <Route exact path="/curriculum" element={<CurriculumTable />} />
          <Route exact path="/school" element={<SchoolTable />} />
          <Route exact path="/contact" element={<ContactTable />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
   </UserProvider>
   </BrowserRouter>
  );
}

export default App;
