import React from 'react'
import { NavLink} from 'react-router-dom'
import "./header.css"
import { useLocation } from 'react-router-dom'


const Header = () => {
    const location= useLocation()

  return (
    <div>
    <div className='headerBox'>
        <div className='header-in'>
            <NavLink to="/"> 
            {location.pathname === '/' ?
            <img src="/img/LogoAFZw.png" alt="Logo Afz" className='logoAfz' />:
            <img src="/img/LogoAFZb.png" alt="Logo Afz" className='logoAfz' />}
            </NavLink>
            <div className={location.pathname === '/'? "display-none" :'header-menu'}>
            <div className='header-item'>
                <NavLink to="/contact" className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
              CONTACTOS
                </NavLink>
                </div>
            <div className='header-item'>
                <NavLink to='/cotizadores' className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
                COTIZACIONES
                </NavLink>
                </div>
            <div className='header-item'>
                <NavLink to='/school' className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
                ESCUELA
                </NavLink>
            </div>
            <div className='header-item'>
                <NavLink to='/curriculum' className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
               CURRICULUMS
                </NavLink>
            </div>
         {/*    <div className='header-item'>
                <NavLink to='/prensa' className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
               PRENSA
                </NavLink>
            </div> */}
            <div className='header-item'>
                <NavLink to='' className={({isActive})=>isActive ? "header-category-selected" : "header-category"}>
               LOGOUT
                </NavLink>
            </div>
            <div className='header-item'>
            <a href='https://afianzadora.online/Account/LogIn' className='header-category-special'>
            AFZ.ONLINE
            </a></div>
            </div>
        </div>      
    </div>
    </div>

  )
}

export default Header