export const Validation = (e, name, type, required = false, maxLength = false, minLength = false, loginData, errors) => {

    const value= e.target.value
      const changedInfoInput = { ...loginData, [e.target.name]:value }; 
      const err = { ...errors }
      const filterMail = /.*@[a-z0-9.-]*/i;
      switch (type) {
         case 'email':
          changedInfoInput[name] = e.target.value;
            if(required) {
              err[name] = e.target.value ? false : "El campo es requerido";
            }
            if(maxLength && !err[name]) {
              err[name] = e.target.value.length > maxLength ? `El campo debe tener hasta 75 caracteres` : false;
            }
            if(minLength && !err[name]) {
              err[name] = e.target.value.length < minLength ? `El campo debe tener más de 8 caracteres` : false;
            }
            if(filterMail.test(value) === false){
              err[name] = "Ingrese un mail válido"
            }
            break;
            case 'password':
              changedInfoInput[name] = e.target.value;
              if(required) {
                err[name] = e.target.value ? false : "El campo es requerido";
              }
              if(maxLength && !err[name]) {
                err[name] = e.target.value.length > maxLength ? `El campo debe tener hasta 12 caracteres` : false;
              }
              if(minLength && !err[name]) {
                err[name] = e.target.value.length < minLength ? `El campo debe tener más de 6 caracteres` : false;
              }
              break;
      
            default:
            break;
          }
         
       return {changedInfoInput: changedInfoInput, err: err, value: value}
        }
  