import React from 'react'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'


const ExportExcel = ({apiData, fileName}) => {
    const fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension='.xlsx'
    const exportToCSV = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };
  return (
   
    <button className="export-button"
   onClick={(e) => exportToCSV(apiData, fileName)}>EXPORTAR</button>
 
  )
}

export default ExportExcel