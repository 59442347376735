import './loader.css'

const Loader = ({ loading }) => {
  return (
    <div className={`loader-container ${loading ? 'visible' : ''}`}>
      <div className="loader-bar"></div>
    </div>
  );
};

export default Loader;