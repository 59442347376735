import axios from 'axios';
const {REACT_APP_ENDPOINT} = process.env;

export const login = async (loginData) => {
	const response = await axios.post(`${REACT_APP_ENDPOINT}public-api/login`, loginData);

	const token = response.data.token;
	if (token) {
		localStorage.setItem('user', JSON.stringify(response.data.token));
	}
	return response.data;
};

export const isAuthenticated = () => {
	const user = localStorage.getItem('user');
	if (!user) {
		return null
	}
	return JSON.parse(user);
};