import React, {useState} from 'react'
import './home.css'
import { Validation } from '../Validations';
import { login } from '../../services/AuthService';
import Loader from '../Loader/Loader.jsx';

const Home = () => {
  const [errors, setErrors]= useState({})
  const [loginData, setLoginData] = useState({
    userEmail: "",
    userPassword:""
  });
  const [loading, setLoading] = useState(false)
  
  const handleChange = (e, name, type, required = false, maxLength = false, minLength = false) => {
    const {changedInfoInput, value, err}= Validation(e, name, type, required,maxLength, minLength, loginData, errors)
          setLoginData({...changedInfoInput, [e.target.name]:value});
          setErrors(err);
        }

  const handleSubmit= async (e)=>{
    e.preventDefault();
    setLoading(true);
    for (const error in errors) {
      if (errors[error]) {
        return;
      } else {
        try {
          await login(loginData);
          window.location.href = `${window.location.origin}/contact`;
        } catch (error) {
          console.error('error', error);
        }finally {
          setLoading(false);
        }
      }}
    }


  return (
    <div className='home-background'>
      <h2 className='login-title'>LOGIN</h2>
      <form onSubmit={handleSubmit}>
       <div className='input-box'> 
            <input name="userEmail" value={loginData.userEmail} className='white-input' type="email" onChange= {e => handleChange(e, 'userEmail', 'email', true, 75, 8)}  placeholder='Email'  required={true}/> 
           {errors['userEmail'] && <p className='error-white'>{errors['userEmail']}</p>}
        </div>
        <div className='input-box'> 
            <input name="userPassword" value={loginData.userPassword} className='white-input' type="password" onChange= {e => handleChange(e, 'userPassword', 'password', true, 12, 6)}  placeholder='Password'  required={true}/> 
            {errors['userPassword'] && <p className='error-white'>{errors['userPassword']}</p>} 
        </div>
        <Loader loading={loading} />
        <button  type="submit" className="in-button"> {loading ? "CARGANDO" : "INGRESAR"}</button>
        </form>
    </div>
  )
}

export default Home