import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'bootstrap-4-react'
import './prensa.css'



function Prensa (){
    const [prensaData, setPrensaData]= useState({
        media:"",
        publicationDate:"",
        title: "",
        text:"",
        img:"",
        alt:""  
    })
    const handleChange=(e)=>{
        e.preventDefault();
        const value= e.target.value
        setPrensaData({...prensaData, [e.target.name]:value})
    }
  return (
    <>
    <div className="form-box-prensa">
    <Link to="/prensa-table"><button className="see-button">
            VER LA LISTA</button></Link>
    <Form className="form">
        <Form.Group /* onSubmit={handleSubmit} */>
          <label htmlFor="exampleInputEmail1">Medio donde se publicó</label>
          <Form.Input type="text" placeholder="Ingresar Medio" value={prensaData.media} name="media" onChange={handleChange}/><br/>
          <label htmlFor="exampleInputEmail1">Fecha de Publicación</label>
          <Form.Input type="date" value={prensaData.publicationDate} name="publicationDate" onChange={handleChange}/><br/>
          <label htmlFor="exampleInputEmail1">Título de la Nota</label>
          <Form.Input type="text" placeholder="Ingresar Tílulo de la Nota" value={prensaData.title} name="title" onChange={handleChange}/><br/>
          <label htmlFor="exampleInputEmail1">Texto de la Nota</label>
          <Form.Textarea type="text" placeholder="Ingresar Texto de la Nota" value={prensaData.text} name="text" onChange={handleChange}/><br/>
          <label htmlFor="exampleInputEmail1">Imagen</label>
          <Form.File type="file" value={prensaData.img} name="img" onChange={handleChange}/><br/>
          <label htmlFor="exampleInputEmail1">Alt Imagen</label>
          <Form.Input type="text" placeholder="Ingresar descripción de la imagen" value={prensaData.alt} name="alt" onChange={handleChange}/><br/> 

        </Form.Group>
        <button className="export-button" type='submit'>ENVIAR</button>
    </Form>
    </div>
    </>
  )
}

export default Prensa