import axios from 'axios'

export const privateGET = async (path) => {
    const user = localStorage.getItem('user')
    try {
      const response = await axios.get(path, {
          headers: {
              "x-auth": user,
              "content-type": "application/json"
          }
      })
      return response.data
    } catch (error) {
       return {
        error: true
       }
    }
  }

  export const privatePOST = async (path, body) => {
    try {
      const response = await axios.post(path, body)
      return response.data
    } catch (error) {
       return {
        error: true
       }
    }
  }